import React from 'react';
import PropTypes from 'prop-types';
import styles from './hiddenText.module.scss';

export default function HiddenText(props) {
   const { as, children } = props;

   return React.createElement(
      as,
      {
         className: styles.container,
      },
      children,
   );
}

HiddenText.propTypes = {
   as: PropTypes.string,
};

HiddenText.defaultProps = {
   as: 'span',
};
