import IndexWrapper from 'Pages/IndexWrapper';
import API from '../api';
import getBuildTime from '../services/build/getBuildTime';
import getInitialReduxState from '../services/page/getInitialReduxState';

const colorBrandIcons = process.env.NEXT_PUBLIC_STORE_ID === 'city' ? 'white' : 'black';

export default function Home(props) {
   return <IndexWrapper {...props} colorBrandIcons={colorBrandIcons} />;
}

export async function getStaticProps(context, storeId = process.env.NEXT_PUBLIC_STORE_ID) {
   const startTime = new Date();
   const initialReduxState = await getInitialReduxState(storeId);
   const newnessProducts = await API.products.getNewnessProducts(storeId);
   const promotedProducts = await API.products.getPromotedProducts(storeId);
   const brands = await API.brands.get(storeId);
   let additionalPageInfo = { isValidPage: true };
   if (!newnessProducts.success) {
      throw new Error(newnessProducts.error);
   }
   if (!promotedProducts.success) {
      throw new Error(promotedProducts.error);
   }
   if (
      initialReduxState.site.main.storeId !== storeId &&
      !initialReduxState.site.micrositios.find((m) => m.storeId === storeId)
   ) {
      additionalPageInfo = { isValidPage: false, invalidName: storeId };
   }

   return {
      props: {
         initialReduxState,
         buildInfo: {
            generatedAt: startTime.toLocaleTimeString(),
            execTime: getBuildTime(startTime),
            path: '/index',
         },
         newnessProducts,
         promotedProducts,
         brands,
         additionalPageInfo,
      },
      // Next.js will attempt to re-generate the page:
      // - When a request comes in
      // - At most once 5 second
      revalidate: 10, // In seconds
   };
}
