/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/order */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import Brands from '../../domain/CarouselsHome/Brands';
import Layout from '../../domain/Layout';
import useBuildInfo from '../../hooks/useBuildInfo';
import styles from '../../styles/pages/index.module.scss';
import generateHrefLink from '../../services/page/generateLinkHref';
import { useRouter } from 'next/router';
import useTrackingQueue from '../../hooks/useTrackingQueue';
import dynamic from 'next/dynamic';
import useMetatagsData from '../../hooks/useMetatagsData';
import HiddenText from 'HiddenText';
import PropTypes from 'prop-types';
import CarouselProducts from 'CarouselProducts';
import { setLoggedUser, resetCheckoutStates } from '../../redux';
import { destroyCookie } from '../../services/cookies';
import PopUp from 'PopUp';

const BannerItem = dynamic(() =>
   import(/* webpackChunkName: "banner-item" */ '../../domain/Index/BannersList/BannerItem'),
);
const Benefits = dynamic(() => import('../../domain/CarouselsHome/Benefits'), { ssr: false });
const Chat = dynamic(() => import('ChatIVS'), { ssr: false });

export default function IndexWrapper(props) {
   const {
      buildInfo,
      promotedProducts,
      newnessProducts,
      brands,
      colorBrandIcons,
      additionalPageInfo,
   } = props;
   const router = useRouter();
   const dispatch = useDispatch();
   const { guestCheckout, noLogin, noLoginPrevious } = useSelector((state) => state.checkout);
   const benefits = useSelector(({ site }) => site?.ribbonBenefits);
   const banners = useSelector((state) => state?.banners);
   const { executeEvent } = useTrackingQueue();
   const { STORE_NAME, STORE_SLOGAN } = useMetatagsData();

   useBuildInfo(buildInfo);

   useEffect(() => {
      if (!additionalPageInfo?.isValidPage) {
         const incorrectPath = `${additionalPageInfo.invalidName}`;
         router.push(router.pathname.replace(incorrectPath, ''));
      }
      switch (true) {
         case guestCheckout:
         case noLogin:
         case noLoginPrevious:
            destroyCookie('token');
            break;
         default:
      }
      dispatch(resetCheckoutStates(false));
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
         dispatch(setLoggedUser(true));
      }
      executeEvent('viewHome');
   }, []);
   const live = useSelector((state) => state.ivs);

   return (
      <Layout
         index
         description="Descubre nuestra amplia gama de productos deportivos que te ayudarán a alcanzar tus metas y disfrutar al máximo de tu pasión por el deporte."
      >
         <PopUp />
         <HiddenText as="h1">
            {STORE_NAME} {STORE_SLOGAN}
         </HiddenText>

         <div className={styles.container}>
            {live.hasVideo && <Chat />}
            {banners.home?.length > 0 ? (
               <div className={styles.bannersHome}>
                  <BannerItem
                     url={generateHrefLink(banners.home[0].url, router)}
                     desktopImage={banners.home[0].desktop_image}
                     mobileImage={banners.home[0].mobile_image}
                     alt={banners.home[0].alt_text}
                     className={styles.bannerLarge}
                     desktopSize={{ width: 1440, height: 443 }}
                     desktopQuality={100}
                     mobileSize={{ width: 768, height: 768 }}
                     mobileQuality={30}
                     preload
                  />
                  {banners.home?.length > 2 && (
                     <BannerItem
                        url={generateHrefLink(banners.home[2].url, router)}
                        desktopImage={banners.home[2].desktop_image}
                        mobileImage={banners.home[2].mobile_image}
                        alt={banners.home[2].alt_text}
                        className={styles.bannerSmall1}
                        desktopSize={{ width: 715, height: 440 }}
                        desktopQuality={100}
                        mobileQuality={30}
                        mobileSize={{ width: 650, height: 400 }}
                     />
                  )}
                  {brands.data.length ? (
                     <div className={cn(styles.carouselBrands, styles.carousel)}>
                        <Brands brands={brands.data} color={colorBrandIcons} />
                     </div>
                  ) : null}
                  {banners.home?.length > 1 && (
                     <BannerItem
                        url={generateHrefLink(banners.home[1].url, router)}
                        desktopImage={banners.home[1].desktop_image}
                        mobileImage={banners.home[1].mobile_image}
                        alt={banners.home[1].alt_text}
                        className={styles.bannerSmall2}
                        desktopSize={{ width: 715, height: 440 }}
                        desktopQuality={100}
                        mobileQuality={30}
                        mobileSize={{ width: 650, height: 400 }}
                     />
                  )}
               </div>
            ) : (
               <div style={{ height: '3vw' }} />
            )}

            {benefits?.length > 0 && (
               <>
                  <div className={styles.benefitsMobile}>
                     <Benefits benefits={benefits} carrousel />
                  </div>
                  <div className={styles.benefitsDesktop}>
                     <Benefits benefits={benefits} />
                  </div>
               </>
            )}

            {promotedProducts?.data?.length > 0 && (
               <div className={styles.carousel}>
                  <CarouselProducts title="Promociones" products={promotedProducts.data} />
               </div>
            )}

            {newnessProducts?.data?.length > 0 && (
               <div className={styles.carousel}>
                  <CarouselProducts title="Destacados" products={newnessProducts.data} />
               </div>
            )}

            <div>
               <div className={styles.bannersGender}>
                  {banners.genders?.length > 0 && (
                     <BannerItem
                        url={generateHrefLink(banners.genders[0].url, router)}
                        desktopImage={banners.genders[0].desktop_image}
                        mobileImage={banners.genders[0].mobile_image}
                        alt={banners.genders[0].alt_text}
                        desktopSize={{ width: 600, height: 900 }}
                        mobileSize={{ width: 600, height: 900 }}
                     />
                  )}
                  {banners.genders?.length > 1 && (
                     <BannerItem
                        url={generateHrefLink(banners.genders[1].url, router)}
                        desktopImage={banners.genders[1].desktop_image}
                        mobileImage={banners.genders[1].mobile_image}
                        alt={banners.genders[1].alt_text}
                        desktopSize={{ width: 600, height: 900 }}
                        mobileSize={{ width: 600, height: 900 }}
                     />
                  )}
                  {banners.genders?.length > 2 && (
                     <BannerItem
                        url={generateHrefLink(banners.genders[2].url, router)}
                        desktopImage={banners.genders[2].desktop_image}
                        mobileImage={banners.genders[2].mobile_image}
                        alt={banners.genders[2].alt_text}
                        desktopSize={{ width: 600, height: 900 }}
                        mobileSize={{ width: 600, height: 900 }}
                     />
                  )}
               </div>

               <div className={styles.bannersSport}>
                  {banners.sports?.[0] && (
                     <BannerItem
                        url={generateHrefLink(banners.sports[0].url, router)}
                        desktopImage={banners.sports[0].desktop_image}
                        mobileImage={banners.sports[0].mobile_image}
                        alt={banners.sports[0].alt_text}
                        desktopSize={{ width: 900, height: 300 }}
                        mobileSize={{ width: 900, height: 300 }}
                     />
                  )}
                  {banners.sports?.[1] && (
                     <BannerItem
                        url={generateHrefLink(banners.sports[1].url, router)}
                        desktopImage={banners.sports[1].desktop_image}
                        mobileImage={banners.sports[1].mobile_image}
                        alt={banners.sports[1].alt_text}
                        desktopSize={{ width: 900, height: 300 }}
                        mobileSize={{ width: 900, height: 300 }}
                     />
                  )}
                  {banners.sports?.[2] && (
                     <BannerItem
                        url={generateHrefLink(banners.sports[2].url, router)}
                        desktopImage={banners.sports[2].desktop_image}
                        mobileImage={banners.sports[2].mobile_image}
                        alt={banners.sports[2].alt_text}
                        desktopSize={{ width: 900, height: 300 }}
                        mobileSize={{ width: 900, height: 300 }}
                     />
                  )}
               </div>
            </div>
         </div>
      </Layout>
   );
}

IndexWrapper.propTypes = {
   colorBrandIcons: PropTypes.string,
};

IndexWrapper.defaultProps = {
   colorBrandIcons: 'black',
};
