import React from 'react';
import cx from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import generateHrefLink from '../../../services/page/generateLinkHref';
import styles from './brand-item.module.scss';

function BrandItem(props) {
   const {
      item: { url, name: brandName, brand_icon: brandIcon, brand_icon_white: brandIconWhite },
      color,
   } = props;

   const [viewRef, inView] = useInView({
      threshold: 0,
      rootMargin: '100px 300px',
      triggerOnce: true,
   });

   const styleBrandName = cx({
      [styles.brandName]: true,
      [styles[color]]: true,
   });

   const renderIcon = () => {
      if (!inView) {
         return null;
      }

      if ((color === 'black' && brandIcon) || (color === 'white' && brandIconWhite)) {
         return (
            <Image
               src={color === 'black' ? brandIcon.url : brandIconWhite.url}
               alt={brandName}
               className={styles.image}
               width={128}
               height={65}
               quality={25}
            />
         );
      }

      return <span className={styleBrandName}>{brandName}</span>;
   };

   return (
      <Link href={generateHrefLink(url, useRouter())}>
         <a ref={viewRef}>
            <div className={styles.wrapper}>{renderIcon()}</div>
         </a>
      </Link>
   );
}

export default BrandItem;
