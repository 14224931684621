import { useEffect, useState } from 'react';
import Modal from 'Modal';
import Image from 'next/image';
import styles from './index.module.scss';

const PopUp = () => {
   const date = new Date();
   const [available, setAvailable] = useState(false);
   useEffect(() => {
      setAvailable(!JSON.parse(sessionStorage.getItem('popUpShown') || 'false'));
   }, []);
   if (
      date.getDate() === 8 &&
      date.getHours() <= 20 &&
      date.getMonth() === 10 &&
      date.getFullYear() &&
      available
   ) {
      return (
         <div>
            <Modal
               className={styles.container}
               modalStyles={{ btnColor: 'white' }}
               onClose={() => {
                  setAvailable(false);
                  sessionStorage.setItem('popUpShown', 'true');
               }}
            >
               <Image src={'/assets/popup.jpg'} layout="fill" />
            </Modal>
         </div>
      );
   }
   return null;
};
export default PopUp;
