import React, { useCallback } from 'react';
import Slider from 'Slider';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import BrandItem from '../BrandItem';
import styles from './brands-home.module.scss';

export default function Brands(props) {
   const { brands, color } = props;

   const [viewRef, inView] = useInView({
      threshold: 0,
      rootMargin: '100px',
      triggerOnce: true,
   });

   const renderBrands = useCallback(() => {
      return brands.map((p) => <BrandItem key={p.id} item={p} color={color} />);
   }, []);

   return (
      <div ref={viewRef} className={styles.wrapper}>
         {inView && <Slider>{renderBrands()}</Slider>}
      </div>
   );
}

Brands.propTypes = {
   color: PropTypes.string,
};

Brands.defaultProps = {
   color: 'black',
};
