import { useEffect } from 'react';

const useBuildInfo = (build) => {
   useEffect(() => {
      if (build) {
         console.log(
            `[${build.path}] Build generated at: ${build.generatedAt} (Took ${build.execTime} seconds)`,
         );
      }
   }, [build]);
};

export default useBuildInfo;
